/* #region Start General */
.section {
  position: relative;
  background-color: #fff;
  padding: 2rem 2rem 2rem 2rem;
  z-index: 1;
}

.body {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  background-color: #fff;
  -webkit-transition: visibility 0.3s, opacity 0.3s, padding 0.3s, ease-in-out;
  -moz-transition: visibility 0.3s, opacity 0.3s, padding 0.3s, ease-in-out;
  -o-transition: visibility 0.3s, opacity 0.3s, padding 0.3s, ease-in-out;
  transition: visibility 0.3s, opacity 0.3s, padding 0.3s, ease-in-out;
}

.title {
  margin-bottom: 2rem;
  color: var(--pttLinkColor);
  font-size: 1.1rem;
}

.titleNoBottom {
  margin-bottom: 0 !important;
}

.title > a {
  position: relative;
  margin-left: 0.2rem;
  padding: 0.5rem 0 0 2rem;
  color: var(--pttLinkColor);
}

.title > a::before {
  position: absolute;
  top: 1rem;
  left: 0rem;
  width: 0.7rem;
  height: 0.7rem;
  content: "";
  border-top: solid 3px var(--pttYellowColor);
  border-right: solid 3px var(--pttYellowColor);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  -o-transform-origin: right;
  transform-origin: right;
}

.list {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 2rem auto 0 auto;
}

.list > div {
}

.list > div > .container {
  position: relative;
  height: 12rem;
  margin: 1rem;
  padding: 0 1rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  -webkit-box-shadow: 0 15px 35px -20px rgb(0, 0, 0, 0.3);
  -moz-box-shadow: 0 15px 35px -20px rgb(0, 0, 0, 0.3);
  -o-box-shadow: 0 15px 35px -20px rgb(0, 0, 0, 0.3);
  box-shadow: 0 15px 35px -25px rgb(0, 0, 0, 0.3);
  z-index: 1;
}

.list > .noData {
  width: 100%;
  height: auto;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.list > div > .container > .content {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
  height: 100%;
}

.list > div > .container > .content > .datetime {
  position: relative;
  top: 40%;
  -webkit-transform: translateY(-25%);
  -moz-transform: translateY(-25%);
  -o-transform: translateY(-25%);
  transform: translateY(-25%);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
  color: var(--pttLinkColor);
  text-align: center;
  z-index: 1;
}

.list > div > .container > .content > .datetime {
  position: relative;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.list > div > .container > .content > .datetime > .number {
  color: var(--pttLinkColor);
  font-size: 1.8rem;
}
.list > div > .container > .content > .datetime > .bidDate {
  color: var(--pttLinkColor);
  display: flex;
  font-weight: 400;
  margin-bottom: 0.1rem;
  line-height: 110%;
}

.list > div > .container > .content > .datetime > .year {
  background-color: var(--pttYellowColor);
  margin-top: 0.4rem;
  padding: 0.2rem 0;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
  color: var(--pttLinkColor);
}

.list > div > .container > .content > .description {
  padding: 1rem 0 0 1.5rem;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  line-height: 1.6;
}

.list > div > .container > .content > .description > .category {
  display: inline-block;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: solid 2px var(--pttYellowColor);
}

.list > div > .container > .content > .description > p {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.list > div > .container > .readMore {
  position: absolute;
  right: 0;
  bottom: 0;
}

.list > div > .container > .readMore > a {
  margin: 1.5rem 0;
  padding: 0 1.5rem;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
}
.readMore:before {
  content: "";
  position: absolute;
  bottom: 1.2rem;
  left: 50%;
  height: 0.2rem;
  border-radius: 1rem;
  opacity: 0.5;
  width: 0%;
  background-color: var(--pttYellowColor);
  transform: translateX(-50%);
  transition: all 0.4s ease;
}
.readMore:hover:before {
  width: 60%;
}

/* #endregion */
/* End General */

/* #region Start Button Group */
.btnGroup > button > i {
  position: absolute;
  left: 0.1rem;
  bottom: 70%;
  color: var(--dangerBgColor);
  animation: vibration 1s ease 0s 5 normal none;
  z-index: 1;
}
.btnGroup > button > i:hover + p {
  visibility: visible;
  opacity: 1;
}
.btnGroup > button > p {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: -1rem;
  bottom: 150%;
  background-color: #ccc;
  width: auto;
  padding: 0.5rem 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -o-border-radius: 1rem;
  border-radius: 1rem;
  font-size: 0.8rem;
  text-align: left;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btnGroup > button > p::before {
  position: absolute;
  top: 100%;
  left: 1rem;
  content: "";
  border-top: solid 8px #ccc;
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  border-bottom: solid 8px transparent;
}
.moreContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moreButton {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  border: 0.1rem solid #ddd;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  box-shadow: 0 10px 15px -10px #ddd;
}
.moreButton:hover {
  cursor: pointer !important;
  background-color: var(--pttYellowColor);
}

@-webkit-keyframes vibration {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-45deg) scale(1.2);
  }
  15% {
    transform: rotate(-25deg) scale(1.2);
  }
  20% {
    transform: rotate(-35deg) scale(1.2);
  }
  25% {
    transform: rotate(-25deg) scale(1.2);
  }
  30% {
    transform: rotate(-35deg) scale(1.2);
  }
  35% {
    transform: rotate(-45deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes vibration {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-45deg) scale(1.2);
  }
  15% {
    transform: rotate(-25deg) scale(1.2);
  }
  20% {
    transform: rotate(-35deg) scale(1.2);
  }
  25% {
    transform: rotate(-25deg) scale(1.2);
  }
  30% {
    transform: rotate(-35deg) scale(1.2);
  }
  35% {
    transform: rotate(-45deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes vibration {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-45deg) scale(1.2);
  }
  15% {
    transform: rotate(-25deg) scale(1.2);
  }
  20% {
    transform: rotate(-35deg) scale(1.2);
  }
  25% {
    transform: rotate(-25deg) scale(1.2);
  }
  30% {
    transform: rotate(-35deg) scale(1.2);
  }
  35% {
    transform: rotate(-45deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes vibration {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-45deg) scale(1.2);
  }
  15% {
    transform: rotate(-25deg) scale(1.2);
  }
  20% {
    transform: rotate(-35deg) scale(1.2);
  }
  25% {
    transform: rotate(-25deg) scale(1.2);
  }
  30% {
    transform: rotate(-35deg) scale(1.2);
  }
  35% {
    transform: rotate(-45deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* #endregion */
/* #region End Button Group */

/* Visible */
.isActive {
  visibility: visible !important;
  opacity: 1 !important;
  position: relative;
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  /* #region Start General */
  .section {
  }

  .body {
    padding: 0;
  }

  .title {
    font-size: 1rem !important;
  }

  .title > a {
  }

  .title > a > span {
    font-size: 0.9rem !important;
  }

  .title > a::before {
  }

  .list {
  }

  .list > div {
  }

  .list > div > .container {
  }

  .list > .noData {
  }

  .list > div > .container > .content {
  }

  .list > div > .container > .content > .datetime {
  }

  .list > div > .container > .content > .datetime {
  }

  .list > div > .content > .datetime > .number {
    font-size: 1.8rem;
  }
  .list > div > .container > .content > .datetime > .bidDate {
    font-size: 0.8rem !important;
  }

  .list > div > .container > .content > .datetime > .year {
  }

  .list > div > .container > .content > .description {
  }

  .list > div > .container > .content > .description > .category {
    font-size: 1rem !important;
  }

  .list > div > .container > .content > .description > p {
    font-size: 0.9rem !important;
  }

  .list > div > .container > .readMore {
  }

  .list > div > .container > .readMore > a {
    font-size: 0.9rem !important;
    font-weight: var(--fontWeight) !important;
  }
  /* #endregion */
  /* End General */

  /* #region Start Button Group */
  .btnGroup > button > i {
  }
  .btnGroup > button > i:hover + p {
  }
  .btnGroup > button > p {
    font-size: 0.9rem !important;
  }
  .btnGroup > button > p::before {
  }
  /* #endregion */
  /* End Button Group */
}

/* Mobile */
@media only screen and (max-width: 790px) {
  /* #region Start General */
  .section {
    text-align: center;
  }

  .body {
    padding: 0;
  }

  .title {
    position: relative;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem !important;
  }
  .title::before {
    position: absolute;
    top: 0.7rem;
    left: 0;
    background: rgb(241, 241, 241);
    background: linear-gradient(
      90deg,
      transparent 20%,
      rgba(83, 86, 90, 1) 100%
    );
    content: "";
    width: 6rem;
    height: 2px;
  }
  .title::after {
    position: absolute;
    top: 0.7rem;
    right: 0;
    background: rgb(241, 241, 241);
    background: linear-gradient(
      90deg,
      rgba(83, 86, 90, 1) 20%,
      transparent 100%
    );
    content: "";
    width: 6rem;
    height: 2px;
  }
  .title > a {
    margin: 0.5rem 0 0 0;
    padding: 0;
  }
  .title > a > span {
    font-size: 0.9rem !important;
  }
  .title > a::before {
    display: none;
  }

  .list {
  }
  .list > div {
  }
  .list > div > .container {
  }
  .list > .noData {
  }
  .list > div > .container > .content {
  }
  .list > div > .container > .content > .datetime {
  }
  .list > div > .container > .content > .datetime {
  }

  .list > div > .container > .content > .datetime > .bidDate {
    font-size: 0.8rem !important;
  }
  .list > div > .content > .datetime > .number {
  }
  .list > div > .container > .content > .datetime > .year {
  }
  .list > div > .container > .content > .description {
  }
  .list > div > .container > .content > .description > .category {
  }
  .list > div > .container > .content > .description > p {
  }
  .list > div > .container > .readMore {
  }
  .list > div > .container > .readMore > a {
    padding-left: 0rem;

    font-weight: var(--fontWeight) !important;
  }
  .readMore::before {
    position: unset;
    content: "▶";
    color: var(--pttYellowColor);
    display: inline-block;
    margin-right: 5px;
    font-size: 12px;
    transition: transform 0.3s ease;
    opacity: 1;
    bottom: unset;
    left: unset;
    height: unset;
    border-radius: unset;
    width: unset;
    background-color: unset;
    transform: unset;
  }
  .readMore:hover:before {
    width: unset;
  }
  /* #endregion */
  /* End General */

  /* #region Start Button Group */
  .btnGroup {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    gap: 0.5rem 0;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: auto;
  }
  .btnGroup > button {
    position: relative;
    display: flex;

    font-size: 0.9rem !important;
  }
  .btnGroup > button > i {
  }
  .btnGroup > button > i:hover + p {
  }
  .btnGroup > button > p {
  }
  .btnGroup > button > p::before {
  }
  /* #endregion */
  /* End Button Group */
}
