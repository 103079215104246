/* #region Start General */
.section {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  background-image: url("../../../../assets/images/news/bg.jpg");
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.filter {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  -o-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 0;
}
/* #endregion */
/* End General */

/* #region Start Title */
.title {
  color: var(--lightBgColor);
  font-size: 1.1rem;
  z-index: 1;
}
.title > a {
  position: relative;
  margin-left: 0.2rem;
  padding: 0.5rem 0 0 2rem;
  color: var(--pttYellowColor);
}
.title > a::before {
  position: absolute;
  top: 1rem;
  left: 0rem;
  width: 0.7rem;
  height: 0.7rem;
  content: "";
  border-top: solid 3px var(--pttYellowColor);
  border-right: solid 3px var(--pttYellowColor);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  -o-transform-origin: right;
  transform-origin: right;
}
/* #endregion */
/* End Title */

/* #region Start TimeLine */
.timeLine {
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
  position: relative;
  width: 80.5%;
  margin: 0 auto;
  z-index: 1;
  overflow-x: hidden;
  cursor: grab;
  -webkit-transition: visibility 0.3s, opacity 0.3s, max-height 0s ease-in-out;
  -moz-transition: visibility 0.3s, opacity 0.3s, max-height 0s ease-in-out;
  -o-transition: visibility 0.3s, opacity 0.3s, max-height 0s ease-in-out;
  transition: visibility 0.3s, opacity 0.3s, max-height 0s ease-in-out;
}
.timeLine:active {
  cursor: grabbing;
}
.timeLine > li {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  position: relative;
  min-width: 21%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-right: 5%;
}
.timeLine > li:first-child {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  z-index: 2;
}
.timeLine > li > a > .card {
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 1rem;
  cursor: pointer;
  overflow: hidden;
  -webkit-box-shadow: 0 35px 35px -35px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0 35px 35px -35px rgba(0, 0, 0, 1);
  -o-box-shadow: 0 35px 35px -35px rgba(0, 0, 0, 1);
  box-shadow: 0 35px 35px -35px rgba(0, 0, 0, 1);
  -webkit-transition: transform 0.1s ease-in-out;
  -moz-transition: transform 0.1s ease-in-out;
  -o-transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  z-index: 2;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  border: 4px solid var(--pttYellowColor);
}
.timeLine > li > a > .card > .cardFilter {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url("../../../../assets/images/card-filter.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.3;
}
.timeLine > li > a > .card:hover {
  transform: scale(1.1);
}
.timeLine > li > a > .card > .image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}
.timeLine > li > .datetime {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgb(255, 199, 44);
  background: linear-gradient(
    0deg,
    rgba(255, 199, 44, 1) 32%,
    rgba(255, 255, 255, 1) 32%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 5rem;
  height: 5rem;
  margin: 3rem auto;
  border: solid 1px #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  -webkit-box-shadow: 0 0 0 5px rgb(254 200 39),
    0 0 0 10px rgb(254 200 39 / 30%);
  -moz-box-shadow: 0 0 0 5px rgb(254 200 39), 0 0 0 15px rgb(254 200 39 / 30%);
  -o-box-shadow: 0 0 0 5px rgb(254 200 39), 0 0 0 15px rgb(254 200 39 / 30%);
  box-shadow: 0 0 0 2px rgb(254 200 39), 0 0 0 15px transparent,
    0 0 0 35px rgba(254, 200, 39, 0.1);
  z-index: 1;
}
.timeLine > li > .datetime > .arrowone {
  position: absolute;

  height: 2rem;
  border-top: solid 4px var(--pttYellowColor);
  border-right: solid 4px var(--pttYellowColor);
}
.timeLine > li > .datetime > .arrowtwo {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-top: solid 13px var(--pttYellowColor);
  border-right: solid 13px var(--pttYellowColor);
}
.timeLine > li > .datetime > .circle {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 3.5rem;
  border: solid 4px var(--pttYellowColor);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.timeLine > li > .datetime > .date {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.timeLine > li > .datetime > .date > .number {
  position: absolute;
  font-size: 1.6rem;
  margin-top: -4.5rem;
}
.timeLine > li > .datetime > .date > .year {
  position: relative;
  top: 0;
  padding: 0.2rem;
  font-size: 1rem;
  text-align: center;
}
.timeLine > li > .title {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: flex-start;
  color: #fff;
  font-weight: var(--fontWeight);
  line-height: 1.6;
  text-align: center;
  z-index: 2;
  margin: 1rem 0;
  justify-content: center;
}
.timeLine > li > .title > a {
  margin: 0;
  padding: 0;
  color: #fff;
}
.timeLine > li > .title > a::before {
  display: none;
}
.timeLine > li:nth-child(odd) > .datetime > .arrowone {
  top: -65%;
  left: 50%;
}
.timeLine > li:nth-child(odd) > .datetime > .arrowtwo {
  top: 96%;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(135deg);
  -moz-transform: translateX(-50%) rotate(135deg);
  -o-transform: translateX(-50%) rotate(135deg);
  transform: translateX(-50%) rotate(135deg);
}
.timeLine > li:nth-child(even) {
  flex-direction: column-reverse;
}
.timeLine > li:nth-child(even) > .title {
  align-items: flex-end;
}
.timeLine > li:nth-child(even) > .datetime > .arrowone {
  top: 125%;
  left: 50%;
}
.timeLine > li:nth-child(even) > .datetime > .arrowtwo {
  top: -40%;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) rotate(-45deg);
  transform: translateX(-50%) rotate(-45deg);
}
/* #endregion */
/* End TimeLine */

/* #region Start Buttons */
.buttons {
  position: absolute;
  top: 50%;
  left: 2rem;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
  -webkit-box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 1;
}
.buttons > li {
  position: relative;
  width: 4rem;
  height: 4rem;
  background-color: #fff;

  color: var(--pttLinkColor);
  text-align: center;
  line-height: 4rem;
  cursor: pointer;
}
.buttons > li:first-child {
  border-bottom: solid 2px #eee;
}
.buttons > li > i {
  position: relative;
  top: 0.2rem;
  font-size: 1.5rem;
}
.buttons > .timeLinePrevButtonPassive {
  opacity: 0.3;
}
/* #endregion */
/* End Buttons */

/* #region Start Button Group */
.btnGroup {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
/* #endregion */
/* #region End Button Group */

/* Visible */
.isActive {
  visibility: visible !important;
  opacity: 1 !important;
  max-height: 1000px;
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  /* #region Start General */
  .section {
  }
  .filter {
  }
  /* #endregion */
  /* End General */

  /* #region Start Title */
  .title {
    font-size: 1rem !important;
    z-index: 1;
  }
  .title > a {
  }
  .title > a > span {
    font-size: 0.9rem !important;
  }
  .title > a::before {
  }
  /* #endregion */
  /* End Title */

  /* #region Start TimeLine */
  .timeLine {
  }
  .timeLine:active {
  }
  .timeLine > li {
    min-width: 33.333%;
  }
  .timeLine > li:first-child {
  }
  .timeLine > li > a > .card {
  }
  .timeLine > li > a > .card > .cardFilter {
  }
  .timeLine > li > a > .card:hover {
  }
  .timeLine > li > a > .card > .image > img {
  }
  .timeLine > li > .datetime {
  }
  .timeLine > li > .datetime > .arrow {
  }
  .timeLine > li > .datetime > .circle {
  }
  .timeLine > li > .datetime > .date {
  }
  .timeLine > li > .datetime > .date > .number {
    font-size: 1.5rem !important;
  }
  .timeLine > li > .datetime > .date > .year {
  }
  .timeLine > li > .title {
  }
  .timeLine > li:nth-child(odd) > .datetime > .arrow {
  }
  .timeLine > li:nth-child(even) {
  }
  .timeLine > li:nth-child(even) > .title {
  }
  .timeLine > li:nth-child(even) > .datetime > .arrow {
  }
  /* #endregion */
  /* End TimeLine */

  /* #region Start Buttons */
  .buttons {
  }
  .buttons > li {
  }
  .buttons > li:first-child {
  }
  .buttons > li > i {
  }
  .buttons > .timeLinePrevButtonPassive {
  }
  /* #endregion */
  /* End Buttons */
}

/* Mobile */
@media only screen and (max-width: 790px) {
  /* #region Start General */
  .section {
    text-align: center;
  }
  .filter {
  }
  /* #endregion */
  /* End General */

  /* #region Start Title */
  h1.title {
    position: relative;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem !important;
    z-index: 1;
  }
  h1.title::before {
    position: absolute;
    top: 0.7rem;
    left: 0;
    background: var(--pttYellowColor);
    background: linear-gradient(
      90deg,
      transparent 20%,
      var(--pttYellowColor) 100%
    );
    content: "";
    width: 5rem;
    height: 2px;
  }
  h1.title::after {
    position: absolute;
    top: 0.7rem;
    right: 0;
    background: var(--pttYellowColor);
    background: linear-gradient(
      90deg,
      var(--pttYellowColor) 20%,
      transparent 100%
    );
    content: "";
    width: 5rem;
    height: 2px;
  }
  h1.title > a {
    margin-left: 0;
    padding: 0.5rem 0 0 0;
  }
  h1.title > a > span {
    font-size: 0.9rem !important;
  }
  h1.title > a::before {
    display: none;
  }
  /* #endregion */
  /* End Title */

  /* #region Start TimeLine */
  .timeLine {
    width: 100%;
  }
  .timeLine:active {
  }
  .timeLine > li {
    min-width: 100%;
  }
  .timeLine > li:first-child {
  }
  .timeLine > li > a > .card {
  }
  .timeLine > li > a > .card > .cardFilter {
  }
  .timeLine > li > a > .card:hover {
  }
  .timeLine > li > a > .card > .image > img {
  }
  .timeLine > li > .datetime {
  }
  .timeLine > li > .datetime > .arrow {
  }
  .timeLine > li > .datetime > .circle {
  }
  .timeLine > li > .datetime > .date {
  }
  .timeLine > li > .datetime > .date > .number {
    font-size: 1.5rem !important;
  }
  .timeLine > li > .datetime > .date > .year {
  }
  .timeLine > li > .title {
  }
  .timeLine > li:nth-child(odd) > .datetime > .arrow {
  }
  .timeLine > li:nth-child(even) {
  }
  .timeLine > li:nth-child(even) > .title {
  }
  .timeLine > li:nth-child(even) > .datetime > .arrow {
  }
  /* #endregion */
  /* End TimeLine */

  /* #region Start Buttons */
  .buttons {
    display: none;
  }
  .buttons > li {
  }
  .buttons > li:first-child {
  }
  .buttons > li > i {
  }
  .buttons > .timeLinePrevButtonPassive {
  }
  /* #endregion */
  /* End Buttons */
}
